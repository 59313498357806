import Chartkick from "chartkick"
import Chart from "chart.js/dist/Chart.min"

Chart.defaults.global.elements.rectangle.borderWidth = 0;
Chart.defaults.global.elements.arc.borderWidth = 0;
Chart.defaults.global.elements.line.borderWidth = 0;

Chartkick.options = {
  download: true
};

Chartkick.use(Chart);